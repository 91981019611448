import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ImageInfo from "../components/ImageInfo";
import Seo from "../components/seo";
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import ConditionalLayout from '../components/conditionallayout';
const _ = require("lodash");

const Imagetemplate = ({data, location}) => {

  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <ConditionalLayout location={location}>
            <Seo postPath={postNode.fields.slug} postNode={postNode} postSEO title={post.title} />
            <Helmet>
              <title>{`${_.upperFirst(post.title)} | MIK`}</title>
            </Helmet>
            <ImageInfo post={post} location={location} pageContext={data.pageContext}/>
          </ConditionalLayout>
        )
      }
    </ModalRoutingContext.Consumer>
  )
}
export default Imagetemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ImagePostBySlug($slug: String!)
  {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date
        photo
        category
        tags
        opera
        role
        house
        img {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 70)
          }
        }
      }
    }
  }
`;
