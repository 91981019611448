import React, {Fragment} from 'react';
import { GatsbyImage, getSrc} from "gatsby-plugin-image";
import { MdPhotoCamera} from "react-icons/md";
import {navigate} from 'gatsby';
import {usePostListingQuery} from '../hooks/usePostListingQuery';
import {IoIosArrowForward, IoIosArrowBack} from "react-icons/io";
import {useSwipeable, config} from 'react-swipeable';

const ImageInfo = (props) => {

  const gDriveDownload = getSrc(props.post.img);
  let imageYear = new Date(props.post.date).getFullYear();
  let data = usePostListingQuery();
  let sameCatList = [];
  let post = props.post;
  let postIndex;
  let findPosts = () => {
    let tempArray = [];
    data.allMarkdownRemark.edges.map((edge, index) => (
      tempArray.push(edge)
    ))
    sameCatList = tempArray;
  }
  let findCurrentIndex = () => {
    postIndex = sameCatList.findIndex(obj => obj.node.frontmatter.title === post.title)
  }
  let next = (e) => {
    if (e) {
      e.stopPropagation()
    }
    if(postIndex || postIndex === 0) {
      let nextPost
      // Start over if at the end
      if (postIndex + 1 === sameCatList.length) {
        nextPost = sameCatList[0]
      } else {
        nextPost = sameCatList[postIndex + 1]
      }
      navigate(`${nextPost.node.fields.slug}`, { state: { modal: true }})
    }
  }
  let prev = (e) => {
    if (e) {
      e.stopPropagation()
    }
    if(postIndex || postIndex === 0) {
      let prevPost
      // Start over if at start.
      if (postIndex === 0) {
        prevPost = sameCatList.slice(-1)[0]
      } else {
        prevPost = sameCatList[postIndex - 1]
      }
      navigate(`${prevPost.node.fields.slug}`, { state: { modal: true }})
    }
  }
  findPosts();
  findCurrentIndex();
  const handlers = useSwipeable({
    onSwipedRight: (eventData) => prev(),
    onSwipedLeft: (eventData) => next(),
  ...config,
  });
    return (
      <>
      <div className="image flex center" {...handlers}>
        <div className="prev"><IoIosArrowBack className="btn" size={32} onClick={((e)=> prev(e))} /></div>
        <div className="next"><IoIosArrowForward size={32} className="btn" onClick={((e)=> next(e))} /></div>
        <div className="imgWrap flex center column">
          <GatsbyImage image={props.post.img.childImageSharp.gatsbyImageData} alt={`${props.post.title} - ${props.post.photo}`}/>
          <div className="imgTextWrap flex row center">
            {props.post.opera ? <div className="flex row center">
              <h2>{props.post.opera} </h2>
              <h3>{props.post.role} </h3>
            </div> : null}
            {props.post.house ? <div><p>{props.post.house} {imageYear} </p></div> : null}
            <div>
              <a
                className="flex row center"
                id="downloadImageButton"
                href={gDriveDownload}
                download={`${props.post.title}-photo-${props.post.photo}.jpg`}
                >
                  <MdPhotoCamera size={16}/>
                  <p>{props.post.photo}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
    )
  }
export default ImageInfo;
